import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as CompasSVG } from '../../assets/img/compas.svg';
import './CustomGoogleAutocomplete.scss';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';

const libraries: Libraries = ['places'];

const options = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 10000
};

const CustomGoogleAutocomplete = ({ clearAddress, setClearAddress, setAddress, isDisabled }) => {
  const [autocomplete, setAutocomplete]: any = useState(null);
  const [geolocation, setGeolocation]: any = useState({});
  const [geolocationStatus, setGeolocationStatus] = useState({ status: false, message: '' });
  const inputRef: any = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo',
    libraries
  });

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: 'us' },
        types: ['geocode']
      });

      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();
        if (place && place.formatted_address) {
          inputRef.current.value = place.formatted_address;
          setAddress(place.formatted_address);
        }
      });

      const latitude = geolocation.latitude;
      const longitude = geolocation.longitude;
      if (latitude && longitude) {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

        // Reverse geocode the latitude and longitude to get the address
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            const address = results !== null ? results[0].formatted_address : 'Address not found';
            autocompleteInstance.setFields(['formatted_address']);
            autocompleteInstance.set('place', { formatted_address: address });
          } else {
            console.error('Geocode was not successful for the following reason:', status);
          }
        });
      }

      setAutocomplete(autocompleteInstance);
    }
  }, [isLoaded, geolocation]);

  useEffect(() => {
    if (inputRef.current && clearAddress) {
      inputRef.current.value = '';
      setClearAddress(false);
    }
  }, [clearAddress]);

  const success = useCallback(
    (pos) => {
      const crd = pos.coords;
      setGeolocation({ latitude: crd.latitude, longitude: crd.longitude });
      setGeolocationStatus({ status: false, message: '' });
    },
    [setGeolocation, setGeolocationStatus]
  );

  const error = useCallback(() => {
    setGeolocationStatus({ status: true, message: 'Location access denied' });
    setTimeout(() => setGeolocationStatus({ status: false, message: '' }), 3000);
  }, [setGeolocationStatus]);

  const checkGeolocationPermission = async () => {
    if (navigator.geolocation && !geolocationStatus.status) {
      setGeolocationStatus({ status: true, message: 'Loading...' });
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      setGeolocationStatus({ status: true, message: 'Geolocation not supported' });
      setTimeout(() => setGeolocationStatus({ status: false, message: '' }), 3000);
    }
  };

  return (
    <>
      <div className="formRequestInputContainer formRequestInputContainerGap">
        <label htmlFor="address" className="formRequestLabel">
          Address or point of interest
          <input
            type="text"
            className="formRequestInput"
            id="address"
            ref={inputRef}
            disabled={isDisabled}
          />
          <div className="autocompleteMobileContainer">
            <div className="autocompleteMobileStatus">
              {geolocationStatus.status && geolocationStatus.message}
            </div>
            <button
              className="autocompleteMobileBtn"
              onClick={checkGeolocationPermission}
              disabled={isDisabled}>
              <CompasSVG />
              Current location
            </button>
          </div>
        </label>
      </div>
    </>
  );
};

export default CustomGoogleAutocomplete;
