import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import './RadialTool.scss';
import { LABELS } from '../constans/constans';
import {
  processFetchData,
  findCheapestProperties,
  calculateAngle,
  calculatePercentile
} from '../constans/helpers';
import { MarkerPosition } from '../constans/types';
import { coordinatesWithinRadius } from '../helpers/coordinatesWithinRadius';
import ReCAPTCHA from 'react-google-recaptcha';
import useMediaQuery from '../hooks/useMediaQuery';

import { ReactComponent as MarkerSVG } from '../assets/img/Meter.svg';
import { ReactComponent as ResetSVG } from '../assets/img/reset.svg';
import { ReactComponent as RentArrow } from '../assets/img/rent-arrow.svg';
import { ReactComponent as IconSVG } from '../assets/img/info.svg';
import { ReactComponent as BlackMarkerSVG } from '../assets/img/black-mark.svg';
import spiner from '../assets/img/orange_spiner.gif';

import CustomGoogleAutocomplete from '../components/Autocomplete/CustomGoogleAutocomplete.component';
import ListingTable from '../components/ListingTable/ListingTable.component';
import Map from '../components/Map/Map.component';
import RequestAccessMapModal from '../components/Modal/RequestAccessMapModal.component';
import ErrorMapModal from '../components/Modal/ErrorMapModal.component';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage.component';
import CounterInput from '../components/CounterInput/CounterInput.component';
import Header from '../components/Header/Header.component';
import Footer from '../components/Footer/Footer.component';
import StaticSections from '../components/StaticSections/StaticSections.component';
import HomeSection from '../components/HomeSection/HomeSection.component';
import Tabs from '../components/Tabs/Tabs.component';

function RadialTool() {
  const [address, setAddress]: any = useState(null);
  const [eachPay, setEachPay]: any = useState(null);
  const [roommates, setRoommates]: any = useState(1);
  const [proptype, setProptype]: any = useState(null);
  const [beds, setBeds]: any = useState(null);
  const [baths, setBaths]: any = useState(null);

  const [fetchResponse, setFetchResponse]: any = useState({});
  const [fetchResponseListing, setFetchResponseListing]: any = useState({});
  const [cheapestProperties, setCheapestProperties]: any = useState({});
  const [listingDataProcessed, setListingDataProcessed]: any = useState({});
  const [specialData, setSpecialData]: any = useState([]);

  const [tableType, setTableType] = useState<'cheapest' | 'closest' | 'special'>('cheapest');

  const [panorama, setPanorama]: any = useState(false);
  const [rotationFinished, setRotationFinished] = useState(true);

  const [modal, setModal] = useState(false);
  const [blur, setBlur] = useState(true);
  const [blurMsg, setBlurMsg] = useState(false);

  const [clearAddress, setClearAddress] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);

  const [errorQuickView, setErrorQuickView] = useState(false);
  const [errorMap, setErrorMap] = useState(false);

  const [showCaptcha, setShowCaptcha] = useState(false);

  const reportRef = useRef<HTMLDivElement>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const inputsRef = useRef<HTMLDivElement>(null);
  const meterRef = useRef<HTMLDivElement>(null);
  const loadMapRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (Object.keys(fetchResponse).length !== 0 && modal === false) {
      setBlur(true);
      setBlurMsg(true);
    }

    if (modal === true && errorMap === true) {
      setBlur(true);
      setBlurMsg(true);
    }
  }, [roommates, eachPay]);

  useEffect(() => {
    const rotatingBox = document.querySelector('.rent-arrow');

    const handleTransitionEnd = () => {
      setRotationFinished(true);
    };
    const handleTransitionStart = () => {
      setRotationFinished(false);
    };
    rotatingBox?.addEventListener('transitionend', handleTransitionEnd);
    rotatingBox?.addEventListener('transitionstart', handleTransitionStart);
  }, [fetchResponse, eachPay, roommates]);

  useEffect(() => {
    if (reportRef.current) {
      const yOffset = -85;
      const y = reportRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [fetchResponse]);

  useEffect(() => {
    if (loadMapRef.current) {
      const yOffset = -85;
      const y = loadMapRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [loadingMap]);

  const scrollToElement = useCallback(
    (number) => {
      let ref;
      if (number === 0) {
        ref = inputsRef;
      } else if (number === 1) {
        ref = meterRef;
      } else if (number === 2) {
        ref = loadMapRef;
      }
      if (ref.current) {
        const yOffset = -85;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    [inputsRef, meterRef, loadMapRef]
  );

  const handleTableTypeChange = (type) => {
    setTableType(type);
  };

  const openModal = (mean) => {
    setModal(true);
    // handleOpenListing(mean);
    setBlur(true);
  };

  const handleResetForm = () => {
    setAddress('');
    setEachPay(null);
    setClearAddress(true);
    setRoommates(1);
    setProptype(null);
    setBeds(null);
    setBaths(null);
    setFetchResponse({});
    setFetchResponseListing({});
    setCheapestProperties({});
    setListingDataProcessed({});
    setPanorama(false);
    setModal(false);
    setBlur(true);
    setBlurMsg(false);
    setErrorQuickView(false);
    setErrorMap(false);
    setSpecialData([]);
  };

  const totalPay = eachPay !== null ? eachPay * roommates : 0;

  // CALCULATIONS FLOW START
  const memoizedValues = useMemo(() => {
    const { mean, std_dev } = fetchResponse;
    let arrowToMin = false;
    let arrowToMax = false;
    let resultDescription: React.ReactNode = '';
    let resultTitle: React.ReactNode = '';
    let yourRentPrice = totalPay;
    let primary_percentile_05 = mean - 1.645 * std_dev;
    let primary_percentile_95 = mean + 1.645 * std_dev;
    let percentile_05 = calculatePercentile(primary_percentile_05, primary_percentile_95, 0.05);
    let percentile_25 = calculatePercentile(primary_percentile_05, primary_percentile_95, 0.25);
    let percentile_75 = calculatePercentile(primary_percentile_05, primary_percentile_95, 0.75);
    let percentile_95 = calculatePercentile(primary_percentile_05, primary_percentile_95, 0.95);
    let rentPercentage = Math.round(
      ((yourRentPrice - primary_percentile_05) / (primary_percentile_95 - primary_percentile_05)) *
        100
    );

    if (rentPercentage <= 0) {
      arrowToMin = true;
      rentPercentage = 0;
    } else if (rentPercentage >= 100) {
      arrowToMax = true;
      rentPercentage = 100;
    }

    if (rentPercentage <= 25) {
      resultTitle = (
        <h3 className="resultSummaryTitle resultSummaryTitleRed">
          <span>
            Your budget is<span style={{ color: '#c24040' }}> too low</span>
          </span>
        </h3>
      );
      resultDescription = (
        <span>
          <strong>{100 - rentPercentage}%</strong> of the rents in this area are higher than your
          budget.
          <br />
          <br />A reasonable budget starts at{' '}
          <strong>${Math.round(percentile_25).toLocaleString().split(/\s/).join(',')}</strong>
        </span>
      );
    } else {
      resultDescription = (
        <span>
          <strong>{rentPercentage}%</strong> of the rents in this area are within your budget
        </span>
      );
      if (rentPercentage <= 75) {
        resultTitle = (
          <h3 className="resultSummaryTitle resultSummaryTitleGreen">
            <span>
              Your budget seems<span style={{ color: '#196FAF' }}> reasonable</span>
            </span>
          </h3>
        );
      } else if (rentPercentage > 75) {
        resultTitle = (
          <h3 className="resultSummaryTitle resultSummaryTitleBlue">
            <span>
              Your budget is<span style={{ color: '#74B566' }}> great</span> for this area
            </span>
          </h3>
        );
      }
    }

    let arrowAngleValue = calculateAngle(rentPercentage);
    if (arrowToMin === true || arrowAngleValue <= -13) {
      arrowAngleValue = -13;
    }
    if (arrowToMax === true || arrowAngleValue >= 195) {
      arrowAngleValue = 195;
    }

    return {
      arrowAngleValue,
      resultDescription,
      resultTitle,
      rentPercentage,
      percentile_05,
      percentile_25,
      percentile_75,
      percentile_95
    };
  }, [fetchResponse, totalPay]);

  const {
    arrowAngleValue,
    resultDescription,
    resultTitle,
    rentPercentage,
    percentile_05,
    percentile_25,
    percentile_75,
    percentile_95
  } = memoizedValues;
  // CALCULATUONS FLOW END

  // MAP CALCULATIONS FLOW START
  const memoizedMapValues = useMemo(() => {
    let centerPosition;
    const markerPositionsGreen: MarkerPosition[] = [];
    const markerPositionsBlue: MarkerPosition[] = [];
    if (Object.keys(listingDataProcessed).length !== 0) {
      centerPosition = {
        lat: parseFloat(listingDataProcessed.search_latitude),
        lng: parseFloat(listingDataProcessed.search_longitude)
      };

      listingDataProcessed.nearby_properties.forEach((item) => {
        const { latitude, longitude, flag, address, baths, bedrooms, price, property_type } = item;
        const position = { lat: latitude, lng: longitude };
        const formattedPrice = price ? '$' + price.toLocaleString().split(/\s/).join(',') : '-';
        const marker = { position, address, baths, bedrooms, price: formattedPrice, property_type };

        if (flag === 'green') {
          markerPositionsGreen.push(marker);
        } else if (flag === 'blue') {
          markerPositionsBlue.push(marker);
        }
      });
    }
    return {
      centerPosition,
      markerPositionsGreen,
      markerPositionsBlue
    };
  }, [listingDataProcessed]);

  const { centerPosition, markerPositionsGreen, markerPositionsBlue } = memoizedMapValues;
  // MAP CALCULATIONS FLOW END

  const increment = () => {
    if (roommates < 4) {
      setRoommates((prevRoomates) => prevRoomates + 1);
    }
  };
  const decrement = () => {
    if (roommates > 1) {
      setRoommates((prevRoomates) => prevRoomates - 1);
    }
  };

  const handleBuildReport = async () => {
    setLoading(true);
    let bathsValue = '1';
    let prop = '';
    if (baths === '1+') {
      bathsValue = '1.5';
    } else if (baths === '1') {
      bathsValue = '1';
    } else {
      bathsValue = '';
    }
    if (proptype === 'Houses/SFR') {
      prop = 'house';
    } else if (proptype === 'Apt/Condo') {
      prop = 'apartment';
    }

    try {
      const response = await fetch(
        `https://toolsapi.rentometer.com:3000/renterui?address=${address}&bedrooms=${beds}&bathrooms=${bathsValue}&building_type=${prop}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setFetchResponse(result);
      return result;
    } catch (error) {
      setFetchResponse({ error: error });
      setErrorQuickView(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenListing = async (mean) => {
    setLoadingMap(true);
    let bathsValue = '';
    let prop = '';
    if (baths === '1+') {
      bathsValue = '1.5';
    } else if (baths === '1') {
      bathsValue = '1';
    } else {
      bathsValue = '';
    }
    if (proptype === 'Houses/SFR') {
      prop = 'house';
    } else if (proptype === 'Apt/Condo') {
      prop = 'apartment';
    }

    try {
      const response = await fetch(
        `https://toolsapi.rentometer.com:3000/renterui/map?address=${address}&bedrooms=${beds}&bathrooms=${bathsValue}&building_type=${prop}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setFetchResponseListing(result);
      const cheapest = findCheapestProperties(result, totalPay);
      const processedData = processFetchData(result, totalPay, mean);
      if (processedData.nearby_properties.length === 0) {
        setErrorMap(true);
      }
      setCheapestProperties(cheapest);
      setListingDataProcessed(processedData);

      const responseSpecials = await fetch('/special.json');
      const resultSpecial = await responseSpecials.json();
      const center = {
        lat: parseFloat(result.search_latitude),
        lng: parseFloat(result.search_longitude)
      };
      const asd = coordinatesWithinRadius(resultSpecial, center);
      setSpecialData(asd);

      if ('error' in result) {
        setErrorMap(true);
      }
    } catch (error) {
      setErrorMap(true);
    } finally {
      setLoadingMap(false);
    }
  };

  const combinedFetchData = async () => {
    try {
      const dataFromFirstFetch = await handleBuildReport();
      await openModal(dataFromFirstFetch.mean);
    } catch (error) {
      setErrorQuickView(true);
    }
  };

  const handleProcessListingData = () => {
    setErrorMap(false);
    try {
      const cheapest = findCheapestProperties(fetchResponseListing, totalPay);
      const processedData = processFetchData(fetchResponseListing, totalPay, fetchResponse.mean);
      if (processedData.nearby_properties.length === 0) {
        setErrorMap(true);
      }
      setCheapestProperties(cheapest);
      setListingDataProcessed(processedData);
    } catch (error) {
      setErrorMap(true);
      console.log(error);
    } finally {
      if (modal === true) {
        setBlurMsg(false);
      } else {
        setBlur(false);
        setBlurMsg(false);
      }
    }
  };

  const isDisabled = () => {
    return Object.keys(fetchResponse).length !== 0 ? true : false;
  };

  const handleChangeCaptcha = (value) => {
    if (value) {
      setShowCaptcha(false);
      combinedFetchData();
      setTimeout(() => {
        recaptchaRef.current.reset();
      }, 2000);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      {isMobile && (
        <Tabs
          scrollToElement={scrollToElement}
          inputsRef={inputsRef}
          meterRef={meterRef}
          loadMapRef={loadMapRef}
          update={Object.keys(fetchResponse).length !== 0}
        />
      )}

      <main className="main">
        <HomeSection />
        <section className="formWrapper">
          <div className="container formWrapperContainer">
            <div className="formRequest" ref={inputsRef} id="0">
              <CustomGoogleAutocomplete
                clearAddress={clearAddress}
                setClearAddress={setClearAddress}
                setAddress={setAddress}
                isDisabled={isDisabled()}
              />

              <div className="formRequestRadioContainer">
                <div className="formRequestRadioTitle formRequestRadioTitle-roommate">
                  Occupants (roommates)
                  <div className="tooltip">
                    <IconSVG />
                    <span className="tooltipText">You are included</span>
                  </div>
                </div>
                <CounterInput
                  count={roommates}
                  onIncrement={increment}
                  onDecrement={decrement}
                  isDisabled={isDisabled()}
                />
              </div>

              <div className="formRequestInputContainer">
                <label htmlFor="eachPays" className="formRequestLabel">
                  Each pays
                  <div className="formRequestInputStandard">
                    <span className="prefix">$</span>
                    <input
                      type="number"
                      id="eachPays"
                      value={eachPay ?? ''}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                      }
                      onChange={(e) => setEachPay(e.target.value)}
                    />
                  </div>
                </label>
              </div>

              <div className="formRequestRadioContainer">
                <div className="formRequestRadioTitle">Property Type</div>
                <div className="formRequestRadioItems">
                  {LABELS.propType.map((item, index) => (
                    <label
                      key={index + 'proptype'}
                      className={`formRequestRadioItem ${
                        isDisabled() ? 'formRequestRadioItemDisabled' : ''
                      } ${proptype === item ? 'inputChecked' : ''}`}
                      htmlFor={index + 'proptype'}>
                      <input
                        type="radio"
                        name="proptype"
                        id={index + 'proptype'}
                        checked={proptype === item}
                        onChange={() => setProptype(item)}
                        disabled={isDisabled()}
                      />
                      <label className="formRequestRadioLabel" htmlFor={index + 'proptype'}>
                        {item}
                      </label>
                    </label>
                  ))}
                </div>
              </div>

              <div className="formRequestRadioContainer">
                <div className="formRequestRadioTitle formRequestRadioTitle-bed">Bedrooms</div>
                <div className="formRequestRadioItems">
                  {LABELS.beds.map((item, index) => (
                    <label
                      key={index + 'beds'}
                      className={`formRequestRadioItem ${
                        isDisabled() ? 'formRequestRadioItemDisabled' : ''
                      } ${beds === item ? 'inputChecked' : ''}`}
                      htmlFor={index + 'beds'}>
                      <input
                        type="radio"
                        name="beds"
                        id={index + 'beds'}
                        checked={beds === item}
                        onChange={() => setBeds(item)}
                        disabled={isDisabled()}
                      />
                      <label className="formRequestRadioLabel" htmlFor={index + 'beds'}>
                        {item}
                      </label>
                    </label>
                  ))}
                </div>
              </div>

              <div className="formRequestRadioContainer">
                <div className="formRequestRadioTitle formRequestRadioTitle-bath">
                  Bathrooms (optional)
                </div>
                <div className="formRequestRadioItems">
                  {LABELS.baths.map((item, index) => (
                    <label
                      key={index + 'baths'}
                      className={`formRequestRadioItem ${
                        isDisabled() ? 'formRequestRadioItemDisabled' : ''
                      } ${baths === item ? 'inputChecked' : ''}`}
                      htmlFor={index + 'baths'}>
                      <input
                        type="radio"
                        name="baths"
                        id={index + 'baths'}
                        checked={baths === item}
                        onChange={() => setBaths(item)}
                        disabled={isDisabled()}
                      />
                      <label className="formRequestRadioLabel" htmlFor={index + 'baths'}>
                        {item}
                      </label>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="formRequestSubmit">
              <div className="formRequestSubmitTotal">
                <div className="formRequestSubmitTitle">Total budget</div>
                <div className="formRequestSubmitPrice">
                  <span>$ </span>
                  {totalPay.toLocaleString()}
                </div>
              </div>
              {Object.keys(fetchResponse).length === 0 ? (
                <button
                  className="formRequestSubmitBtn"
                  onClick={() => {
                    setShowCaptcha(true);
                  }}
                  disabled={!address || !proptype || beds === null || !roommates || !eachPay}>
                  Check Rents
                </button>
              ) : (
                <>
                  <button className="formRequestSubmitBtnReset" onClick={handleResetForm}>
                    <ResetSVG />
                    Reset
                  </button>
                </>
              )}
            </div>

            <div className="reCaptcha" style={{ display: showCaptcha ? 'flex' : 'none' }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfAloQpAAAAAK0gJt4_YKiregikmZhcOUnI3526"
                onChange={handleChangeCaptcha}
              />
            </div>

            {loading ? (
              <div className="spinner">
                <img src={spiner}></img>
              </div>
            ) : null}

            {Object.keys(fetchResponse).length !== 0 &&
              (errorQuickView ? (
                <ErrorMessage />
              ) : (
                <div className="result" ref={reportRef}>
                  <div className="resultMain" ref={meterRef} id="1">
                    <div className="resultMainData">
                      <h2 className="resultTitle">Analysis Results</h2>
                      <div className="resultSummaryContent">
                        {resultTitle}
                        <div className="resultSummaryDescription">{resultDescription}</div>
                        <div className="resultSummaryAddress">
                          <BlackMarkerSVG />
                          {address}
                        </div>
                      </div>
                    </div>

                    <div className="resultMainMeter">
                      <div className="indicator">
                        <div className="indicator-box">
                          <span className="min">
                            ${Math.round(percentile_05)?.toLocaleString()}
                          </span>
                          <span className="max">
                            ${Math.round(percentile_95)?.toLocaleString()}
                          </span>
                          <span className="percentile-25">
                            ${Math.round(percentile_25)?.toLocaleString()}
                          </span>
                          <span className="percentile-75">
                            ${Math.round(percentile_75)?.toLocaleString()}
                          </span>
                          <span className="average-rent">
                            ${fetchResponse.mean?.toLocaleString()}
                          </span>
                          <span
                            className="your-rent green"
                            style={{
                              left: rentPercentage <= 50 ? '-20px' : 'auto',
                              right: rentPercentage <= 50 ? 'auto' : '-20px'
                            }}>
                            ${totalPay?.toLocaleString()}
                          </span>
                          <span
                            className="rent-arrow"
                            style={{
                              transform: `${
                                arrowAngleValue
                                  ? 'rotate(' + arrowAngleValue + 'deg)'
                                  : 'rotate(-12deg)'
                              }`
                            }}>
                            <RentArrow />
                            <div
                              className="rent-line"
                              style={{ display: `${rotationFinished ? 'block' : 'none'}` }}></div>
                          </span>
                          <MarkerSVG
                            className={
                              rotationFinished
                                ? rentPercentage <= 25
                                  ? 'animated-pink'
                                  : rentPercentage >= 75
                                    ? 'animated-green'
                                    : ''
                                : ''
                            }
                          />
                        </div>
                        <div className="indicator-colors">
                          <div className="indicator-color-title">
                            <h5 className="indicator-color-budget">Your Budget</h5>
                            <div className="indicator-color-desc">
                              For this area, your budget is
                            </div>
                          </div>
                          <div className="indicator-color">
                            <div className="color-box"></div>
                            Too low
                          </div>
                          <div className="indicator-color">
                            <div className="color-box"></div>
                            Reasonable
                          </div>
                          <div className="indicator-color">
                            <div className="color-box"></div>
                            Great
                          </div>
                        </div>
                      </div>
                      <div className="resultSummaryData">
                        Results based on {fetchResponse.samples}, {fetchResponse.bedrooms}-bedroom
                        rentals seen within
                        <br /> 12 months within a {fetchResponse.radius_miles} mile radius
                      </div>
                    </div>
                  </div>

                  {loadingMap === true ? (
                    <div className="spinner spinnerListing">
                      <img src={spiner}></img>
                    </div>
                  ) : (
                    <div className="resultListingContainer" ref={loadMapRef} id="2">
                      <ErrorMapModal
                        isError={errorMap}
                        recalculate={handleProcessListingData}
                        disableRecalculate={blurMsg}
                        modal={modal}
                        percentile25={percentile_25}
                      />
                      <div
                        className="resultMsg"
                        style={{ display: blurMsg && !errorMap ? 'block' : 'none' }}>
                        <div>
                          Please click the &quot;Recalculate&quot; button to view updated results.
                        </div>
                        <button className="resultMsgBtn" onClick={handleProcessListingData}>
                          Recalculate
                        </button>
                      </div>
                      <div
                        className="resultListing"
                        style={{ filter: `${blur || errorMap ? 'blur(12px)' : 'blur(0px)'}` }}>
                        <div className="resultListingMap">
                          <Map
                            centerPosition={centerPosition}
                            markerPositionsGreen={markerPositionsGreen}
                            markerPositionsBlue={markerPositionsBlue}
                            radius={fetchResponse.radius_miles}
                            panorama={panorama}
                            setPanorama={setPanorama}
                            avarege={fetchResponse.mean}
                            median={fetchResponse.median}
                            samples={fetchResponse.samples}
                            specialData={specialData}
                            tableType={tableType}
                          />
                        </div>
                        <ListingTable
                          data={listingDataProcessed.nearby_properties}
                          dataCheapest={cheapestProperties.nearby_properties}
                          setPanorama={setPanorama}
                          panorama={panorama}
                          handleTableTypeChange={handleTableTypeChange}
                          tableType={tableType}
                          totalPay={totalPay}
                          avarege={fetchResponse.mean}
                          proptypeOrigin={proptype}
                          specialData={specialData}
                        />
                      </div>
                      <RequestAccessMapModal
                        handleOpenListing={handleOpenListing}
                        mean={fetchResponse.mean}
                        modal={modal}
                        setModal={setModal}
                        setBlur={setBlur}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </section>
        <StaticSections />
      </main>

      <Footer />
    </div>
  );
}

export default RadialTool;
