import React from 'react';
import './SpecialCard.scss';

import { Box, Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as WebCard } from '../../assets/img/web.svg';
import { ReactComponent as MailCard } from '../../assets/img/mail.svg';
import { ReactComponent as PhoneCard } from '../../assets/img/phone.svg';

import useMediaQuery from '../../hooks/useMediaQuery';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#EF6F22'),
  backgroundColor: '#EF6F22',
  '&:hover': {
    backgroundColor: '#EF6F22'
  }
}));

const PhoneButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#5796D2'),
  backgroundColor: '#5796D2',
  '&:hover': {
    backgroundColor: '#5796D2'
  }
}));

const OutlineButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '2px solid #5796D2',
  '&:hover': {
    border: '2px solid #5796D2'
  }
}));

const SpecialCard = ({ card, panorama, setPanorama, totalPay, avarege, proptype }) => {
  const splitAddress = card['Formatted Address'].split(', ');
  let beforeComma = splitAddress[0];
  let afterComma = splitAddress.slice(1).join(', ');

  const isSmallDeviceWidth = useMediaQuery('(max-width: 420px)');

  //   let marketAvaragePercent = Math.round(((card.rawPrice - avarege) / avarege) * 100);
  //   let marketBudgetPercent = Math.round(((card.rawPrice - totalPay) / totalPay) * 100);

  return (
    <div className="cardContainer">
      <Box
        display="flex"
        flexDirection="column"
        //height={isSmallDeviceWidth ? '250px' : '200px'}
        justifyContent="space-between">
        <Box marginBottom="24px">
          {card['Property Name'] && (
            <h5
              style={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px'
              }}>
              {card['Property Name']}
            </h5>
          )}
          <h5
            style={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px'
            }}>
            {card['Formatted Address']}
          </h5>
        </Box>

        <Box display="flex" flexDirection="column" gap="12px">
          <Box display="flex" flexDirection="column" gap="8px">
            <Box
              display="flex"
              flexDirection={isSmallDeviceWidth ? 'column' : 'row'}
              justifyContent="space-between"
              gap="5px">
              <OutlineButton
                variant="outlined"
                className="cardContainerWeb"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  border: '2px solid #5796D2',
                  color: '#5796D2',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '25px',
                  width: '100%'
                }}
                onClick={() => {
                  if (card['Web Address']) {
                    window.open(card['Web Address'], '_blank');
                  }
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: 0
                  }}>
                  <WebCard /> Web address
                </div>
              </OutlineButton>
              <ColorButton
                variant="contained"
                className="cardContainerContact"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  backgroundColor: '#EF6F22',
                  width: '100%'
                }}
                onClick={() => {
                  if (card['Contact Us URL']) {
                    window.open(card['Contact Us URL'], '_blank');
                  }
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: 0
                  }}>
                  <MailCard fill="white" /> Contact us
                </div>
              </ColorButton>
            </Box>

            <PhoneButton
              variant="contained"
              className="cardContainerContact"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                //backgroundColor: '#EF6F22',
                width: '100%'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  padding: 0
                }}>
                <PhoneCard fill="white" />{' '}
                <a
                  href={`tel:+${card['Phone Number']}`}
                  style={{
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px'
                  }}>
                  {card['Phone Number']}
                </a>
              </div>
            </PhoneButton>
          </Box>

          <div className="cardTime">Verified on {card.date_collected}</div>
        </Box>
      </Box>
      {/* <div className="cardHeader">
        <div className="cardHeaderAddress">
          {beforeComma}
          {afterComma}
        </div>
        {proptype === 'Apt/Condo' ? <ApartmentCard /> : <HomeCard />}
      </div>
      <div className="cardBudget">{card.price}/month</div>
      <ul className="cardData">
        <li>
          <BedCard />
          {card.bedrooms}
        </li>
        <li>
          <BathCard />
          {card.bathrooms}
        </li>
        <li>
          <SizeCard />
          {card.size} s.f.
        </li>
      </ul>
      <div className="cardInfo">
        <span style={{ color: `${marketAvaragePercent < 0 ? '#74B566' : '#C24040'}` }}>
          {Math.abs(marketAvaragePercent)}%
        </span>
        {marketAvaragePercent < 0 ? 'below' : 'above'} market average
      </div>
      <div className="cardInfo">
        <span style={{ color: `${marketBudgetPercent < 0 ? '#74B566' : '#C24040'}` }}>
          {Math.abs(marketBudgetPercent)}%
        </span>
        {marketBudgetPercent < 0 ? 'below' : 'above'} your budget
      </div>
      <div className="cardButtons">
        <button className="cardBtn cardBtnContacts" disabled>
          Contacts
        </button>
        <button
          className="cardBtn cardBtnMap"
          onClick={() => {
            if (
              JSON.stringify(panorama) ==
              JSON.stringify({ lat: card.latitude, lng: card.longitude })
            ) {
              setPanorama(false);
            } else {
              setPanorama({ lat: card.latitude, lng: card.longitude });
            }
          }}>
          {JSON.stringify(panorama) == JSON.stringify({ lat: card.latitude, lng: card.longitude })
            ? 'Go back to map'
            : 'Show on map'}
        </button>
      </div>
      <div className="cardTime">Published on {card.listDate}</div> */}
    </div>
  );
};

export default SpecialCard;
