import React, { useState, useEffect } from 'react';
import './Tabs.scss';

import { ReactComponent as InputTab } from '../../assets/img/inputTab.svg';
import { ReactComponent as MeterTab } from '../../assets/img/meterTab.svg';
import { ReactComponent as ListingTab } from '../../assets/img/listingTab.svg';

const Tabs = ({ scrollToElement, inputsRef, meterRef, loadMapRef, update }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [intersectingElement, setIntersectingElement] = useState<Element | null>(null);

  useEffect(() => {
    const ioConfiguration = {
      rootMargin: '-50% 0% -50% 0%',
      threshold: 0
    };
    const observer = new IntersectionObserver((entries, observer) => {
      setIntersectingElement(entries[0].target);
    }, ioConfiguration);

    const elementsToObserve = [inputsRef, meterRef, loadMapRef].filter(
      (ref) => ref && ref.current
    ) as React.RefObject<HTMLDivElement>[];
    elementsToObserve.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      elementsToObserve.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [update, inputsRef, meterRef, loadMapRef]);

  useEffect(() => {
    if (intersectingElement) {
      if (intersectingElement.id === '0') {
        setActiveTab(0);
      } else if (intersectingElement.id === '1') {
        setActiveTab(1);
      } else if (intersectingElement.id === '2') {
        setActiveTab(2);
      }
    }
  });

  const handleTabClick = (e, index) => {
    e.preventDefault();
    setActiveTab(index);
    scrollToElement(index);
  };

  return (
    <div className="tabContainer">
      <nav className="container tabItems">
        <button
          className={`tabLabel ${activeTab === 0 ? 'tabActive' : ''}`}
          onClick={(e) => handleTabClick(e, 0)}>
          <InputTab />
          Inputs
        </button>
        <button
          className={`tabLabel ${activeTab === 1 ? 'tabActive' : ''}`}
          onClick={(e) => handleTabClick(e, 1)}
          disabled={meterRef.current === null ? true : false}>
          <MeterTab />
          Result
        </button>
        <button
          className={`tabLabel ${activeTab === 2 ? 'tabActive' : ''}`}
          onClick={(e) => handleTabClick(e, 2)}
          disabled={loadMapRef.current === null ? true : false}>
          <ListingTab />
          Details
        </button>
      </nav>
    </div>
  );
};

export default Tabs;
