interface Coordinate {
  latitude: number;
  longitude: number;
}

function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

export function calculateDistance(coord1: Coordinate, coord2): number {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(coord2.lat - coord1.latitude);
  const dLon = degreesToRadians(coord2.lng - coord1.longitude);

  const lat1 = degreesToRadians(coord1.latitude);
  const lat2 = degreesToRadians(coord2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusKm * c;
}

export function coordinatesWithinRadius(
  coordinates: Coordinate[],
  position,
  radiusKm: number = 4.8
): Coordinate[] {
  return coordinates.filter((coord) => calculateDistance(coord, position) <= radiusKm);
}
